import React, { useState } from "react";
import { Drawer, Button, Popover } from "antd";
import { MenuOutlined, ProfileOutlined } from "@ant-design/icons";
import "./navbar.css";
import logo from "./../../logo.svg";
import { Link } from "react-router-dom";
import FileList from "../content/FileList";

const content = (
  <div className="spec-div">
    <FileList />
  </div>
);

const NavBar = ({ menu }) => {
  const [visible, setVisible] = useState(false);
  return (
    <nav className="navbar">
      <Button
        className="menu"
        type="primary"
        icon={<MenuOutlined />}
        onClick={() => setVisible(true)}
      ></Button>
      <Drawer
        title="Topics"
        placement="left"
        onClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        open={visible}
      >
        {menu}
      </Drawer>
      <div className="d-flex align-items-center justify-content-between">
        <Link to="/">
          <img src={logo} className="logo" alt="logo" />
        </Link>
        <div className="mx-3 text-end">
          <Popover placement="bottomRight" content={content} trigger={"click"}>
            <Button type="primary" shape="circle" icon={<ProfileOutlined />} />
          </Popover>
        </div>
        {/* {menu.props.topics[menu.props.selectedKey].name} */}
      </div>
    </nav>
  );
};
export default NavBar;
